.roster-h2 {
    padding-bottom: 20px;
}
.roster {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    background: #fff;
    height: 50vh;
    overflow: hidden;
    overflow-y: scroll;
    border-radius: 15px;
}

thead tr {
    position: sticky;
    top: 0; /* Sticks to the top of the nearest scrolling ancestor */
    background-color: #f2f2f2; /* Ensures the header background isn't transparent */
    z-index: 10; /* Keeps the header above other content */
    padding-top: 20px;
}

table {
    
    width: 100%;
    height: 80vh;
    border-collapse: collapse;
    margin: 0; /* Added to fit within the .roster without additional spacing */
}

th, td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}



th {
    background-color: #f2f2f2;
}



@media (max-width: 768px) {
    th, td {
        padding: 12px;
    }

    .roster {
        width: 90%;
    }
}