.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh; /* Adjust the height as needed */
  overflow: hidden; /* Prevents any spillover from the video */
  position: relative;
  color: #fff; /* Adjust text color as needed */
  text-align: center;
  padding: 20px;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures video covers the area without distortion */
  z-index: -1; /* Keeps the video behind the content */
}
  
  .hero-section h1 {
    font-size: 3rem; /* Adjust font-size as needed */
    margin-bottom: 20px;
  }
  
  .hero-section p {
    font-size: 1.5rem; /* Adjust font-size as needed */
    margin-bottom: 40px;
  }
  
  .hero-section button {
    padding: 10px 30px;
    font-size: 1rem;
    color: #fff;
    background-color: red; /* Adjust button background color as needed */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .hero-section button:hover {
    color: black;
    background-color: white; /* Adjust button hover color as needed */
  }
  
  /* Add more styles or media queries to make the hero section responsive */
  