*, body {
  margin: 0;
  font-family: "Anton", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  

}

#root {
  width: 100%;
  overflow-x: hidden;
}

@media (max-width: 768px)  {
  body {
    transform: rotate(0deg);
  }
}
